import React from 'react';
import PropTypes from 'prop-types';
import Image from 'components/image';
import cn from 'classnames';
import Icon from 'components/icon';
import HeadingCropper from 'components/heading-cropper';
import RecipeDetails, {
  sizes as recipeDetailsSizes,
} from 'components/recipe-details';

const RecipeTeaser = ({
  className,
  image,
  heading,
  headingTagLevel = 3,
  url,
  recipeDetails,
}) => {
  const HeadingTag = `h${headingTagLevel}`;
  return (
    <a href={url} className={cn('recipe-teaser', className)}>
      <div
        className={cn('recipe-teaser__image-container', {
          'recipe-teaser__image-container_no-image': !image,
        })}
      >
        {image && (
          <Image
            {...image}
            className="recipe-teaser__image image_size_recipe-teaser"
          />
        )}
      </div>
      {heading && (
        <HeadingTag className="recipe-teaser__heading">
          <HeadingCropper
            text={heading}
            maxLinesMobile={6}
            maxLinesDesktop={3}
          />
        </HeadingTag>
      )}
      <Icon className="recipe-teaser__icon" name="arrow-right-large" />
      {recipeDetails && (
        <RecipeDetails
          {...recipeDetails}
          size={recipeDetailsSizes.small}
          className="recipe-teaser__details"
          useFocusableDetails={false}
        />
      )}
    </a>
  );
};

RecipeTeaser.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  image: PropTypes.exact(Image.propTypes),
  heading: PropTypes.string,
  headingTagLevel: PropTypes.oneOf([2, 3]),
  url: PropTypes.string,
  recipeDetails: PropTypes.exact(RecipeDetails.propTypes),
};

RecipeTeaser.viewModelMeta = {
  className: 'ignore',
  headingTagLevel: 'ignore',
};

export default RecipeTeaser;
