import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/icon';
import cn from 'classnames';

const ViewSelectItem = ({ view, iconName, selected, onChange = () => {} }) => {
  return (
    <button
      className={cn('view-select__button', {
        'view-select__button_active': selected,
      })}
      onClick={() => onChange(view)}
    >
      <Icon name={iconName} useFill className="view-select__button-icon" />
    </button>
  );
};

ViewSelectItem.propTypes = {
  view: PropTypes.string,
  iconName: PropTypes.string,
  selected: PropTypes.bool,
  onChange: PropTypes.func,
};

ViewSelectItem.viewModelMeta = 'ignore';

export default ViewSelectItem;
