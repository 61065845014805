import SearchTeaser from 'components/search-teaser/search-teaser';
import PropTypes from 'prop-types';

const LoadMoreSearchResults = {};

LoadMoreSearchResults.propTypes = {
  hits: PropTypes.arrayOf(PropTypes.exact(SearchTeaser.propTypes)),
  hasMore: PropTypes.bool.isRequired,
};

export default LoadMoreSearchResults;
