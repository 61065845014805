import PropTypes from 'prop-types';
import TagsFilter from 'components/tags-filter/tags-filter';
import Filter from '../filter/filter';
import RecipeTeaser from 'components/recipe-teaser/recipe-teaser';

const RecipesOverviewResults = {};

RecipesOverviewResults.propTypes = {
  categoriesFilter: PropTypes.exact(TagsFilter.propTypes),
  filters: PropTypes.arrayOf(Filter.propTypes),
  recipesList: PropTypes.arrayOf(RecipeTeaser.propTypes),
  totalCount: PropTypes.number.isRequired,
  pager: PropTypes.shape({
    page: PropTypes.number,
    totalPages: PropTypes.number,
  }),
};

export default RecipesOverviewResults;
