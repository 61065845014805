import React from 'react';
import PropTypes from 'prop-types';
import Link from 'components/link';
import cn from 'classnames';

import MenuLink from 'components/models/menu-link';

const TechniqueNavigation = ({ links }) => {
  return links ? (
    <ul className="technique-navigation">
      {links &&
        links.map((link, index) => {
          return (
            <li
              key={index}
              className={cn('technique-navigation__item', {
                'technique-navigation__item_active': link.isActive,
              })}
            >
              <Link
                {...link}
                className={cn('technique-navigation__link', {
                  'technique-navigation__link_active': link.isActive,
                })}
              />
            </li>
          );
        })}
    </ul>
  ) : null;
};

TechniqueNavigation.propTypes = {
  links: PropTypes.arrayOf(PropTypes.exact(MenuLink.propTypes)),
};

export default TechniqueNavigation;
