import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

// NOTE: When running tests, importing SVGs directly will make the application crash, so all icons are exported as noops instead.
const icons = require(
  process.env.NODE_ENV === 'test'
    ? '../../assets/inline-icons/icons-noop.js'
    : '../../assets/inline-icons/icons.js'
).default;

const Icon = ({ className, name, style, useFill }) => {
  const defaultProps = icons[name].customDefaultProps;

  return icons[name]
    ? React.createElement(icons[name], {
        ...(defaultProps && defaultProps),
        className: cn('icon', className, { '-use-fill-color': useFill }),
        focusable: 'false',
        style,
      })
    : null;
};

Icon.propTypes = {
  className: PropTypes.string,
  name: PropTypes.oneOf(Object.keys(icons)).isRequired,
  style: PropTypes.shape({}),
  useFill: PropTypes.bool,
};

Icon.viewModelMeta = 'ignore';

export default Icon;
