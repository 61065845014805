import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useDidUpdateEffect from 'hooks/use-did-update-effect';
import TechniqueStep from 'components/models/technique-step';
import Slider from 'react-slick';
import Image from 'components/image';
import Video from 'components/video';
import Icon from 'components/icon';
import PoorText from 'components/poor-text/poor-text';
import stringHelper from 'js/string-helper';

const TechniqueStepsCarousel = ({
  steps,
  prevButtonText,
  nextButtonText,
  carouselAriaLabel,
  lang = {
    carouselAriaRoleDescription: 'karusell',
    browseSlidesAriaLabel: 'Bla i lysbilder',
    stepLabel: 'Steg',
    currentStepAriaLabel: 'Steg {0} av {1}',
  },
}) => {
  const sliderRef = useRef();
  const sliderStepsRef = useRef([]);

  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const handlePrevClick = () => {
    sliderRef.current.slickPrev();
  };
  const handleNextClick = () => {
    sliderRef.current.slickNext();
  };

  useEffect(() => {
    sliderStepsRef.current = sliderStepsRef.current.slice(0, steps.length);
  }, [steps]);

  useDidUpdateEffect(() => {
    if (sliderStepsRef?.current) {
      const currentSlideNode = sliderStepsRef?.current[currentSlideIndex];

      if (currentSlideNode) {
        currentSlideNode.focus();
      }
    }
  }, [currentSlideIndex, sliderStepsRef]);

  const settings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const onAfterChange = index => {
    setCurrentSlideIndex(index);
  };

  return steps ? (
    <section
      className="technique-steps-carousel"
      aria-roledescription={lang.carouselAriaRoleDescription}
      aria-label={carouselAriaLabel}
    >
      <Slider {...settings} ref={sliderRef} afterChange={onAfterChange}>
        {steps.map((item, index) => {
          const slideHeadingId = `technique-step-${index + 1}-title`;
          return (
            <div
              className="technique-steps-carousel__item"
              key={index}
              ref={el => (sliderStepsRef.current[index] = el)}
              role="group"
              aria-roledescription={lang.stepLabel}
              aria-labelledby={slideHeadingId}
            >
              <div className="technique-steps-carousel__item-text-content">
                {item.heading && (
                  <h2
                    id={slideHeadingId}
                    className="technique-steps-carousel__item-heading"
                  >
                    {item.heading}
                  </h2>
                )}
                {item.introHtml && (
                  <PoorText
                    text={item.introHtml}
                    className="technique-steps-carousel__item-intro poor-text_in-technique-steps-carousel"
                  />
                )}
                <div
                  className="technique-steps-carousel__item-navigation"
                  aria-label={stringHelper.format(
                    lang.currentStepAriaLabel || '',
                    currentSlideIndex + 1,
                    steps.length
                  )}
                >
                  <div className="technique-steps-carousel__item-counter">
                    <div className="technique-steps-carousel__item-counter-label">
                      {lang.stepLabel}
                    </div>
                    <div className="technique-steps-carousel__item-counter-numbers">
                      <span className="technique-steps-carousel__item-counter-number">
                        {index + 1}
                      </span>
                      <span className="technique-steps-carousel__item-counter-number">
                        {steps.length}
                      </span>
                    </div>
                  </div>
                  <div
                    className="technique-steps-carousel__item-controls"
                    role="group"
                    aria-label={lang.browseSlidesAriaLabel}
                  >
                    {index !== 0 && (
                      <button
                        onClick={handlePrevClick}
                        aria-label={prevButtonText}
                        className="technique-steps-carousel__item-button technique-steps-carousel__item-button_prev"
                        {...(index !== currentSlideIndex && {
                          tabIndex: '-1',
                        })}
                      >
                        <Icon
                          name="arrow-left-large"
                          className="technique-steps-carousel__item-button-icon"
                        />
                      </button>
                    )}
                    {index !== steps.length - 1 && (
                      <button
                        onClick={handleNextClick}
                        aria-label={nextButtonText}
                        className="technique-steps-carousel__item-button technique-steps-carousel__item-button_next"
                        {...(index !== currentSlideIndex && {
                          tabIndex: '-1',
                        })}
                      >
                        <Icon
                          name="arrow-right-large"
                          className="technique-steps-carousel__item-button-icon"
                        />
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <div className="technique-steps-carousel__item-media">
                {item.video ? (
                  <Video
                    {...item.video}
                    fluid={true}
                    imageSettings={{ fluid: true }}
                    {...(index !== currentSlideIndex && {
                      playButtonTabIndex: '-1',
                    })}
                  />
                ) : (
                  item.image && <Image {...item.image} fluid={true} />
                )}
              </div>
            </div>
          );
        })}
      </Slider>
    </section>
  ) : null;
};

TechniqueStepsCarousel.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.exact(TechniqueStep.propTypes)),
  prevButtonText: PropTypes.string.isRequired,
  nextButtonText: PropTypes.string.isRequired,
  carouselAriaLabel: PropTypes.string.isRequired,
  lang: PropTypes.shape({
    carouselAriaRoleDescription: PropTypes.string.isRequired,
    browseSlidesAriaLabel: PropTypes.string.isRequired,
    stepLabel: PropTypes.string.isRequired,
    currentStepAriaLabel: PropTypes.string.isRequired,
  }).isRequired,
};

export default TechniqueStepsCarousel;
