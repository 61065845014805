import React from 'react';
import PropTypes from 'prop-types';
import Logo from 'components/logo';
import ScrollButton from 'components/scroll-button';
import PoorText from 'components/poor-text';
import FooterMenu from 'components/models/footer-menu';
import Link from 'components/link';
import cn from 'classnames';

const Footer = ({ logo, toTopButtonText, contactInfo, footerMenu }) => {
  return (
    <div className="footer">
      <div className="footer__column">
        {footerMenu && footerMenu.mainMenu && (
          <React.Fragment>
            {footerMenu.mainMenu.heading && (
              <h3 className="footer__heading">{footerMenu.mainMenu.heading}</h3>
            )}
            {footerMenu.mainMenu.links && (
              <ul className="footer__main-menu">
                {footerMenu.mainMenu.links.map((item, index) => {
                  return (
                    <li key={index} className="footer__main-menu-item">
                      <Link
                        {...item}
                        className={cn('footer__main-menu-link', {
                          'footer__main-menu-link_active': item.isActive,
                        })}
                      />
                    </li>
                  );
                })}
              </ul>
            )}
          </React.Fragment>
        )}
      </div>
      <div className="footer__column">
        {footerMenu && (
          <React.Fragment>
            {footerMenu.aboutUs && (
              <React.Fragment>
                {footerMenu.aboutUs.heading && (
                  <h3 className="footer__heading">
                    {footerMenu.aboutUs.heading}
                  </h3>
                )}
                <ul className="footer__menu">
                  {footerMenu.aboutUs.links.map((item, index) => {
                    return (
                      <li key={index} className="footer__menu-item">
                        <Link
                          {...item}
                          className={cn('footer__menu-link', {
                            'footer__menu-link_active': item.isActive,
                          })}
                        />
                      </li>
                    );
                  })}
                </ul>
              </React.Fragment>
            )}
            {footerMenu.followUs && (
              <React.Fragment>
                {footerMenu.followUs.heading && (
                  <h3 className="footer__heading">
                    {footerMenu.followUs.heading}
                  </h3>
                )}
                <ul className="footer__menu">
                  {footerMenu.followUs.links.map((item, index) => {
                    return (
                      <li key={index} className="footer__menu-item">
                        <Link
                          {...item}
                          className={cn('footer__menu-link', {
                            'footer__menu-link_active': item.isActive,
                          })}
                        />
                      </li>
                    );
                  })}
                </ul>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </div>
      <div className="footer__column">
        {contactInfo && contactInfo.heading && (
          <h3 className="footer__heading">{contactInfo.heading}</h3>
        )}
        {contactInfo && contactInfo.textHtml && (
          <PoorText
            text={contactInfo.textHtml}
            className="poor-text_in-footer-contact-info"
          />
        )}
      </div>
      <div className="footer__column">
        <ScrollButton
          label={toTopButtonText}
          className="footer__to-top-button"
          iconName="arrow-top"
          targetElement=".page__root"
        />
        <Logo
          {...logo}
          className="footer__logo"
          imageClass="image_size_footer-logo"
        />
      </div>
    </div>
  );
};

Footer.propTypes = {
  contactInfo: PropTypes.shape({
    heading: PropTypes.string,
    textHtml: PropTypes.string,
  }),
  toTopButtonText: PropTypes.string.isRequired,
  logo: PropTypes.exact(Logo.propTypes),
  footerMenu: PropTypes.exact(FooterMenu.propTypes),
};

export default Footer;
