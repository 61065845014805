import React from 'react';
import PropTypes from 'prop-types';
import Image from 'components/image';
import Icon from 'components/icon';
import cn from 'classnames';
import HeadingCropper from 'components/heading-cropper';
import RecipeDetails, {
  sizes as recipeDetailsSizes,
} from 'components/recipe-details';
import apiHelper from 'js/api-helper';

const SearchTeaser = ({
  image,
  heading,
  url,
  trackUrl,
  color,
  className,
  recipeDetails,
}) => {
  const teaserStyle = {
    backgroundColor: color,
  };

  const handleLinkClick = e => {
    if (trackUrl) {
      var defaultBehaviour = e.ctrlKey;
      if (!defaultBehaviour) {
        e.preventDefault();
      }
      apiHelper.execute(trackUrl, {}).then(_response => {
        if (!defaultBehaviour && url) {
          window.location.href = url;
        }
      });
    }
  };

  return (
    <a
      href={url}
      className={cn('search-teaser', className)}
      style={teaserStyle}
      onClick={handleLinkClick}
    >
      <div
        className={cn('search-teaser__image-container', {
          'search-teaser__image-container_no-image': !image,
        })}
      >
        {image && (
          <Image
            {...image}
            className="search-teaser__image image_size_search-teaser"
          />
        )}
      </div>
      {heading && (
        <h3 className="search-teaser__heading">
          <HeadingCropper
            text={heading}
            maxLinesMobile={6}
            maxLinesDesktop={3}
          />
        </h3>
      )}
      <Icon name="arrow-right-large" className="search-teaser__icon" />
      {recipeDetails && (
        <RecipeDetails
          {...recipeDetails}
          size={recipeDetailsSizes.small}
          className="search-teaser__details"
          useFocusableDetails={false}
        />
      )}
    </a>
  );
};

SearchTeaser.propTypes = {
  image: PropTypes.exact(Image.propTypes),
  heading: PropTypes.string,
  url: PropTypes.string,
  trackUrl: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
  recipeDetails: PropTypes.exact(RecipeDetails.propTypes),
};

SearchTeaser.viewModelMeta = {
  className: 'ignore',
};

export default SearchTeaser;
