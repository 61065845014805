import React, {
  useState,
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import Icon from 'components/icon';
import PropTypes from 'prop-types';
import useEscape from 'hooks/use-escape';
import cn from 'classnames';
import Autosuggest from 'react-autosuggest';
import apiHelper from 'js/api-helper';

const SearchForm = forwardRef(
  (
    {
      searchPageUrl,
      searchInputAriaLabel,
      searchButtonText,
      searchButtonTextHidden = true,
      searchHint,
      query,
      className,
      onInputChange = () => {},
      onInputFocus = () => {},
      onEscPress = () => {},
      onSearch = null,
      autocompleteEndpoint,
      autocompleteSuggestionsAriaLabel,
      searchFormAriaLabel = 'Søkefelt for globalsøk',
    },
    ref
  ) => {
    const [value, setValue] = useState(query || '');
    const [suggestions, setSuggestions] = useState([]);
    const form = useRef(null);
    const searchInput = useRef(null);

    const onSuggestionsClearRequested = () => {
      setSuggestions([]);
    };

    useEffect(() => {
      setValue(query || '');
    }, [query]);

    const onSearchTextChange = (event, { newValue }) => {
      setValue(newValue);
      onInputChange(newValue);
    };

    const getSuggestionsCacheMap = () => {
      var globalContext = window || global;
      if (!globalContext.suggestionsDictionary) {
        globalContext.suggestionsDictionary = new Map();
      }

      return globalContext.suggestionsDictionary;
    };

    const tryGetSuggestionsFromCache = query => {
      return getSuggestionsCacheMap().get(query);
    };

    const putSuggestionsToCache = (query, suggestions) => {
      getSuggestionsCacheMap().set(query, suggestions);
    };

    const onSuggestionsFetchRequested = ({ value }) => {
      const inputValue = value.trim().toLowerCase();
      const inputLength = inputValue.length;
      const query = { q: inputValue };

      if (inputLength !== 0 && autocompleteEndpoint) {
        const suggestionsFromCache = tryGetSuggestionsFromCache(inputValue);
        if (suggestionsFromCache) {
          setSuggestions(suggestionsFromCache);
        } else {
          apiHelper
            .execute(autocompleteEndpoint, query)
            .then(results => {
              setSuggestions(results);
              putSuggestionsToCache(inputValue, results);
            })
            .catch(error => {
              setSuggestions([]);

              // eslint-disable-next-line no-console
              console.log(
                'Unexpected error happened during autocomplete suggestions request:',
                error
              );
            });
        }
      } else {
        setSuggestions([]);
      }
    };

    useImperativeHandle(ref, () => ({
      resetSearchValue() {
        setValue('');
      },
      toggleInputFocus(focus = true) {
        if (searchInput && searchInput.current) {
          focus ? searchInput.current.focus() : searchInput.current.blur();
        }
      },
    }));

    const onFormSubmit = e => {
      if (onSearch) {
        e.preventDefault();
        onSearch({ query: value });
      }
    };

    useEscape(() => {
      onEscPress();
      if (searchInput && searchInput.current) {
        searchInput.current.blur();
      }
    }, [searchInput]);

    const inputProps = {
      placeholder: searchHint,
      value,
      onChange: onSearchTextChange,
      onFocus: onInputFocus,
      ref: searchInput,
      'aria-label': searchInputAriaLabel,
      name: 'q',
      type: 'search',
    };

    const getSuggestionValue = suggestion => suggestion;

    const onSuggestionSelected = () => {
      setTimeout(function () {
        form.current.submit();
      }, 1);
    };

    return (
      <form
        ref={form}
        className={cn('search-form', {
          [className]: className,
        })}
        action={searchPageUrl}
        method="get"
        onSubmit={onFormSubmit}
        role="search"
        aria-label={searchFormAriaLabel}
      >
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={suggestion => <div>{suggestion}</div>}
          inputProps={inputProps}
          renderSuggestionsContainer={({ containerProps, children }) => (
            <div
              aria-label={autocompleteSuggestionsAriaLabel}
              {...containerProps}
            >
              {children}
            </div>
          )}
          onSuggestionSelected={onSuggestionSelected}
        />
        <button className="search-form__button">
          {searchButtonTextHidden && (
            <Icon
              className="search-form__icon"
              name="search"
              useFill
              color="000"
            />
          )}
          <span
            className={cn('search-form__button-text', {
              'search-form__button-text_hidden': searchButtonTextHidden,
            })}
          >
            {searchButtonText}
          </span>
        </button>
      </form>
    );
  }
);

SearchForm.viewModelMeta = {
  className: 'ignore',
  query: 'ignore',
  onInputChange: 'ignore',
  onInputFocus: 'ignore',
  onEscPress: 'ignore',
  searchButtonTextHidden: 'ignore',
};

SearchForm.propTypes = {
  searchHint: PropTypes.string,
  query: PropTypes.string,
  className: PropTypes.string,
  searchPageUrl: PropTypes.string,
  searchInputAriaLabel: PropTypes.string.isRequired,
  searchButtonText: PropTypes.string.isRequired,
  onInputChange: PropTypes.func,
  onInputFocus: PropTypes.func,
  onEscPress: PropTypes.func,
  onSearch: PropTypes.func,
  searchButtonTextHidden: PropTypes.bool,
  autocompleteEndpoint: PropTypes.string,
  autocompleteSuggestionsAriaLabel: PropTypes.string.isRequired,
  searchFormAriaLabel: PropTypes.string,
};

export default SearchForm;
