import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import PageHeading from 'components/page-heading';
import apiHelper from 'js/api-helper';
import SearchSection from 'components/search-section/search-section';
import TagsFilter from 'components/tags-filter/tags-filter';
import SearchForm from 'components/search-form';
import LoadingIndicator from 'components/loading-indicator';
import ErrorMessage from 'components/error-message';
import formHelper from 'js/form-helper';
import stringHelper from 'js/string-helper';

const SearchPage = ({
  lang = {
    autocompleteSuggestionsAriaLabel: 'Foreslåtte søketermer for nettsidesøk',
  },
  heading,
  searchEndpoint,
  loadMoreEndpoint,
  autocompleteEndpoint,
}) => {
  const [results, setResults] = useState(null);
  const [showError, setShowError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [query, setQuery] = useState(formHelper.createQuery());
  const [initDone, setInitDone] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const updateQuery = (updateFunc = q => {}) => {
    setQuery(q => {
      q = Object.assign({}, q);
      updateFunc(q);
      return q;
    });
  };

  const clearResults = () => {
    setResults(results =>
      !results ? results : { ...results, sections: null }
    );
  };

  const refreshResults = () => {
    setResults(Object.assign({}, results));
  };

  const search = () => {
    clearResults();
    setShowError(false);
    setIsLoading(true);
    apiHelper
      .execute(searchEndpoint, query)
      .then(results => {
        formHelper.updateFilterFromQuery(
          query,
          'type',
          results.pageTypeFilter.items
        );
        setResults(results);
      })
      .catch(() => {
        setShowError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const loadMore = section => {
    if (section.isLoading) {
      return;
    }
    const newPage = (section.page ?? 0) + 1;
    section.showError = false;
    section.isLoading = true;
    refreshResults();
    const sectionQuery = Object.assign({}, query);
    sectionQuery.type = section.pageType;
    sectionQuery.page = newPage;
    sectionQuery.queryTrackId = results.queryTrackId;
    apiHelper
      .execute(loadMoreEndpoint, sectionQuery)
      .then(loadMoreResults => {
        section.hits = [...section.hits, ...loadMoreResults.hits];
        section.hasMore = loadMoreResults.hasMore;
        section.page = newPage;
      })
      .catch(() => {
        section.showError = true;
      })
      .finally(() => {
        section.isLoading = false;
        refreshResults();
      });
  };

  const init = () => {
    updateQuery(q => formHelper.fillQueryFromPageQueryString(q));
  };

  useEffect(() => {
    if (!initDone) {
      init();
      setInitDone(true);
    }
  }, []);

  useEffect(() => {
    if (initDone) {
      formHelper.updatePageQueryString(query);
      search();
    }
  }, [query, initDone]);

  const onPageTypeFilterChange = e => {
    updateQuery(q => formHelper.updateQueryFromFilter(q, 'type', e.items));
  };

  return (
    <div className="search-page">
      <PageHeading text={heading} className="page-heading_small" />
      <div className="search-page__container">
        <div className="search-page__hits-text">
          {results
            ? stringHelper.format(
                lang.searchHitsText,
                query.q,
                results.totalCount
              )
            : ''}
        </div>
        <SearchForm
          className="search-page__search-form"
          searchButtonText={lang.searchButtonText}
          searchInputAriaLabel={lang.searchInputAriaLabel}
          searchHint={lang.searchHint}
          searchFormAriaLabel={lang.searchFormAriaLabel}
          autocompleteSuggestionsAriaLabel={
            lang.autocompleteSuggestionsAriaLabel
          }
          query={query.q}
          onSearch={e =>
            updateQuery(q => formHelper.setQueryValue(q, 'q', e.query))
          }
          autocompleteEndpoint={autocompleteEndpoint}
        />
      </div>
      <div className="search-page__content">
        {results && (
          <React.Fragment>
            {results.totalCount === 0 ? (
              <React.Fragment>
                <h3 className="search-page__no-results-heading">
                  {lang.noResultsHeading}
                </h3>
                <div className="search-page__no-results-text">
                  {lang.noResultsText}
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {results.pageTypeFilter && (
                  <TagsFilter
                    {...results.pageTypeFilter}
                    onChange={onPageTypeFilterChange}
                  />
                )}
                {results.sections &&
                  results.sections.map(section => (
                    <SearchSection
                      key={section.pageType}
                      {...section}
                      loadMoreButtonText={lang.loadMoreButtonText}
                      errorMessage={lang.errorMessage}
                      onLoadMoreClick={() => loadMore(section)}
                    />
                  ))}
              </React.Fragment>
            )}
          </React.Fragment>
        )}
        {isLoading && <LoadingIndicator />}
        {showError && <ErrorMessage text={lang.errorMessage} />}
      </div>
    </div>
  );
};

SearchPage.propTypes = {
  lang: PropTypes.shape({
    searchHitsText: PropTypes.string.isRequired,
    noResultsHeading: PropTypes.string.isRequired,
    noResultsText: PropTypes.string.isRequired,
    searchHint: PropTypes.string.isRequired,
    searchButtonText: PropTypes.string.isRequired,
    searchInputAriaLabel: PropTypes.string.isRequired,
    loadMoreButtonText: PropTypes.string.isRequired,
    errorMessage: PropTypes.string.isRequired,
    searchFormAriaLabel: PropTypes.string.isRequired,
    autocompleteSuggestionsAriaLabel: PropTypes.string.isRequired,
  }).isRequired,
  heading: PropTypes.string,
  searchEndpoint: PropTypes.string.isRequired,
  loadMoreEndpoint: PropTypes.string.isRequired,
  autocompleteEndpoint: PropTypes.string,
};

export default SearchPage;
