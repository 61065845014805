import React from 'react';
import Video from 'components/video';
import PropTypes from 'prop-types';
import cn from 'classnames';

const VideoBlock = ({ video, place }) => {
  return video && video.src ? (
    <div
      className={cn('video-block', {
        [`video-block_in-${place}`]: place,
      })}
    >
      <div className="video-block__container">
        <Video
          {...video}
          imageSettings={{
            className: 'image_size_video-block',
          }}
        />
      </div>
    </div>
  ) : null;
};

VideoBlock.viewModelMeta = {
  place: 'ignore',
};

VideoBlock.propTypes = {
  video: PropTypes.exact(Video.propTypes),
  place: PropTypes.string,
};

export default VideoBlock;
