import React from 'react';
import PropTypes from 'prop-types';
import ColoredTag from 'components/colored-tag';

// eslint-disable-next-line view-models/no-errors
export const position = {
  topLeft: 'top-left',
  topCenter: 'top-center',
  topRight: 'top-right',
  bottomLeft: 'bottom-left',
  bottomCenter: 'bottom-center',
  bottomRight: 'bottom-right',
};

const FloatingTag = props => {
  return <ColoredTag {...props} />;
};

FloatingTag.propTypes = Object.assign({}, ColoredTag.propTypes, {
  position: PropTypes.oneOf(Object.values(position)).isRequired,
});

// eslint-disable-next-line view-models/no-errors
export default FloatingTag;
