import SearchSection from 'components/search-section/search-section';
import TagsFilter from 'components/tags-filter/tags-filter';
import PropTypes from 'prop-types';

const SearchResults = {};

SearchResults.propTypes = {
  pageTypeFilter: PropTypes.exact(TagsFilter.propTypes),
  sections: PropTypes.arrayOf(PropTypes.exact(SearchSection.propTypes)),
  queryTrackId: PropTypes.string,
  totalCount: PropTypes.number.isRequired,
};

export default SearchResults;
