import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import Filter from 'components/models/filter';
import Checkbox from 'components/checkbox';
import useToggle from 'hooks/use-toggle';
import cn from 'classnames';
import useBreakpoints from 'hooks/use-breakpoints';
import Icon from 'components/icon';

const recipeFilterPanelId = 'recipe-filter-panel';

const RecipeFilter = forwardRef(
  (
    {
      heading,
      allItemsLabel,
      items,
      name,
      // eslint-disable-next-line no-unused-vars
      onChange = ({ name, items }) => {},
    },
    ref
  ) => {
    const { isDesktop } = useBreakpoints(true);
    const { isActive: panelIsOpenedInMobile, toggle: togglePanel } =
      useToggle(false);

    const [currentItems, setCurrentItems] = useState([]);
    const [allItem, setAllItem] = useState(
      allItemsLabel ? { text: allItemsLabel, value: 'all' } : null
    );

    const updateItem = (targetItem, selected) => {
      setCurrentItems(currentItems =>
        currentItems.map(item =>
          targetItem === item ? { ...item, selected: selected } : item
        )
      );
    };

    const updateAllItems = selected => {
      setCurrentItems(currentItems =>
        currentItems.map(item =>
          item.selected !== selected ? { ...item, selected: selected } : item
        )
      );
    };

    useImperativeHandle(ref, () => ({
      unselectItem(item) {
        updateItem(item, false);
      },
      unselectAll() {
        updateAllItems(false);
      },
    }));

    useEffect(() => {
      setCurrentItems(items);
    }, [items]);

    useEffect(() => {
      setAllItem(allItem =>
        allItem
          ? {
              ...allItem,
              selected: !currentItems.find(i => !i.selected),
            }
          : null
      );
      if (currentItems !== items && currentItems.length > 0) {
        onChange({ name: name, items: currentItems });
      }
    }, [currentItems]);

    return (
      <div className="recipe-filter">
        {isDesktop ? (
          heading && (
            <span className="recipe-filter__heading-text">{heading}</span>
          )
        ) : (
          <button
            onClick={togglePanel}
            className="recipe-filter__toggle-button"
            aria-expanded={panelIsOpenedInMobile}
            aria-controls={recipeFilterPanelId}
          >
            <Icon
              name="toggle"
              className={cn('recipe-filter__toggle-button-icon', {
                'recipe-filter__toggle-button-icon_rotated':
                  panelIsOpenedInMobile,
              })}
            />
            {heading && (
              <span className="ecipes-filter__heading-text">{heading}</span>
            )}
          </button>
        )}
        <div
          className={cn('recipe-filter__panel', {
            'recipe-filter__panel_opened-in-mobile': panelIsOpenedInMobile,
          })}
          id={recipeFilterPanelId}
          {...(!isDesktop && { 'aria-hidden': !panelIsOpenedInMobile })}
        >
          {currentItems.length > 0 && allItem && (
            <div className="recipe-filter__item" key={allItem.value}>
              <Checkbox
                name={name}
                value={allItem.value}
                label={allItem.text}
                checked={allItem.selected}
                onChange={checked => updateAllItems(checked)}
              />
            </div>
          )}
          {currentItems.map(item => {
            return (
              <div
                className={cn('recipe-filter__item', {
                  'recipe-filter__item_grayed-out': item.noHits,
                })}
                key={item.value}
              >
                <Checkbox
                  name={name}
                  value={item.value}
                  label={item.text}
                  checked={item.selected}
                  onChange={checked => updateItem(item, checked)}
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  }
);

RecipeFilter.propTypes = { ...Filter.propTypes };

RecipeFilter.viewModelMeta = 'ignore';

export default RecipeFilter;
