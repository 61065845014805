import React from 'react';
import PropTypes from 'prop-types';
import RichText from 'components/rich-text';
import PoorText from 'components/poor-text';
import FloatingTags from 'components/floating-tags';

const TextWithSidePanel = ({
  heading,
  textHtml,
  sidePanelHeading,
  sidePanelTextHtml,
  sidePanelBackgroundColor,
  sidePanelTags,
}) => {
  var panelStyle = {
    backgroundColor: sidePanelBackgroundColor,
  };
  return (
    <div className="text-with-side-panel">
      <div className="text-with-side-panel__main-content">
        {heading && (
          <h2 className="text-with-side-panel__heading">{heading}</h2>
        )}
        <RichText text={textHtml} />
      </div>
      <div className="text-with-side-panel__side-panel" style={panelStyle}>
        <FloatingTags
          {...sidePanelTags}
          className="floating-tags_in-side-panel"
        >
          <h3 className="text-with-side-panel__side-heading">
            {sidePanelHeading}
          </h3>
        </FloatingTags>
        <PoorText
          text={sidePanelTextHtml}
          className="text-with-side-panel__side-text poor-text_in-side-panel"
        />
      </div>
    </div>
  );
};

TextWithSidePanel.propTypes = {
  heading: PropTypes.string,
  textHtml: PropTypes.string,
  sidePanelHeading: PropTypes.string,
  sidePanelTextHtml: PropTypes.string,
  sidePanelBackgroundColor: PropTypes.string,
  sidePanelTags: PropTypes.exact(FloatingTags.propTypes),
};

export default TextWithSidePanel;
