/* eslint-disable react/no-multi-comp */
import React, { useEffect, useRef } from 'react';
import SearchForm from 'components/search-form';
import Icon from 'components/icon';
import useToggle from 'hooks/use-toggle';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { events } from 'js/emit-event';

const quickSearchFormId = 'quick-search-form';

const QuickSearch = ({
  className,
  showQuickSearchLabel,
  hideQuickSearchLabel,
  searchForm,
}) => {
  const { isActive, toggle, activate, deactivate } = useToggle(false);
  const searchFormRef = useRef(null);

  const resetSearchState = () => {
    deactivate();
  };

  useEffect(() => {
    if (searchFormRef && searchFormRef.current) {
      searchFormRef.current.toggleInputFocus(isActive);
      !isActive && searchFormRef.current.resetSearchValue();
    }
  }, [isActive, searchFormRef]);

  useEffect(() => {
    document.addEventListener(events.OPEN_QUICK_SEARCH, activate);

    return () => {
      document.removeEventListener(events.OPEN_QUICK_SEARCH, activate);
    };
  }, []);

  const onSearchInputFocus = () => {
    activate();
  };

  const renderIcon = isActive => {
    return (
      <Icon
        className="quick-search__icon"
        name={isActive ? 'menu-close' : 'search'}
        useFill
        style={{ color: '#000' }}
      />
    );
  };

  return (
    <div
      className={cn('quick-search', {
        [className]: className,
        'quick-search_active': isActive,
      })}
    >
      <button
        className="quick-search__btn quick-search__btn_mobile quick-search__label-btn"
        aria-label={showQuickSearchLabel}
        onClick={isActive ? null : toggle}
        aria-expanded={isActive}
        aria-controls={quickSearchFormId}
      >
        {renderIcon(false)}
      </button>
      <div className="quick-search__wrapper">
        <div className="quick-search__container">
          <button
            aria-label={isActive ? hideQuickSearchLabel : showQuickSearchLabel}
            className="quick-search__btn quick-search__btn_desktop quick-search__label-btn"
            onClick={isActive ? resetSearchState : activate}
            aria-expanded={isActive}
            aria-controls={quickSearchFormId}
          >
            {renderIcon(isActive)}
          </button>
          <div
            className="quick-search__form-holder"
            aria-hidden={!isActive}
            id={quickSearchFormId}
          >
            <SearchForm
              {...searchForm}
              ref={searchFormRef}
              className={cn('search-form_in-header', {
                'search-form_in-header-active': isActive,
              })}
              onInputFocus={onSearchInputFocus}
              onEscPress={deactivate}
              searchButtonTextHidden={false}
            />
            <button
              aria-label={hideQuickSearchLabel}
              className="quick-search__btn quick-search__btn_mobile"
              onClick={resetSearchState}
            >
              {renderIcon(true)}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

QuickSearch.viewModelMeta = {
  className: 'ignore',
};

QuickSearch.propTypes = {
  className: PropTypes.string,
  searchForm: PropTypes.exact(SearchForm.propTypes).isRequired,
  showQuickSearchLabel: PropTypes.string,
  hideQuickSearchLabel: PropTypes.string,
};

export default QuickSearch;
