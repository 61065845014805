import PropTypes from 'prop-types';
import Image from 'components/image';

const ShareLink = {};

ShareLink.propTypes = {
  icon: PropTypes.exact(Image.propTypes),
  url: PropTypes.string.isRequired,
  label: PropTypes.string,
};

export default ShareLink;
