import React from 'react';
import PropTypes from 'prop-types';
import Image from 'components/image';
import LinkButton from 'components/link-button';
import FloatingTags from 'components/floating-tags';
import FloatingTag from 'components/floating-tag';
import Video from 'components/video';
import ScrollButton from 'components/scroll-button';
import cn from 'classnames';

const TopBannerBlock = ({
  image,
  video,
  tags,
  textLine1,
  textLine2,
  seeAllButtonLink,
  backgroundColor,
  lang = {
    scrollButtonAriaLabel: 'Snarvei til hovedinnholdet på siden',
  },
}) => {
  const RootElement = seeAllButtonLink && seeAllButtonLink.url ? 'a' : 'div';

  const rootElementProps = {
    ...(seeAllButtonLink && {
      href: seeAllButtonLink.url,
      target: seeAllButtonLink.openInNewTab ? '_blank' : null,
    }),
  };

  return (
    <div className="top-banner-block">
      <RootElement
        {...rootElementProps}
        className={cn('top-banner-block__container', {
          'top-banner-block__link': seeAllButtonLink,
        })}
      >
        <div className="top-banner-block__media">
          {video ? (
            <Video
              {...video}
              fluid={true}
              imageSettings={{
                fluid: true,
                className:
                  'top-banner-block__image image_size_top-banner-image',
              }}
            />
          ) : (
            image && (
              <Image
                className="top-banner-block__image image_size_top-banner-image"
                {...image}
                fluid={true}
              />
            )
          )}
        </div>
        <div className="top-banner-block__content" style={{ backgroundColor }}>
          {(textLine1 || textLine2) && (
            <FloatingTags
              items={tags}
              className="top-banner-block__heading-tags"
            >
              <h1 className="top-banner-block__heading">
                {textLine1 && (
                  <span className="top-banner-block__heading-line">
                    {textLine1}
                  </span>
                )}
                {textLine2 && (
                  <span className="top-banner-block__heading-line">
                    {textLine2}
                  </span>
                )}
              </h1>
            </FloatingTags>
          )}

          {seeAllButtonLink && (
            <div className="top-banner-block__btn-holder">
              <LinkButton
                tabindex={1}
                text={seeAllButtonLink.text}
                className="top-banner-block__btn link-button_light-hover"
              />
            </div>
          )}
        </div>
      </RootElement>
      <div className="top-banner-block__bottom-panel">
        <ScrollButton
          className="top-banner-block__scroll-btn"
          iconName="arrow-top"
          targetElement=".content-area"
          ariaLabel={lang.scrollButtonAriaLabel}
        />
      </div>
    </div>
  );
};

TopBannerBlock.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.exact(FloatingTag.propTypes)),
  image: PropTypes.exact(Image.propTypes),
  video: PropTypes.exact(Video.propTypes),
  textLine1: PropTypes.string,
  textLine2: PropTypes.string,
  seeAllButtonLink: PropTypes.exact(LinkButton.propTypes),
  backgroundColor: PropTypes.string,
  lang: PropTypes.shape({
    scrollButtonAriaLabel: PropTypes.string.isRequired,
  }).isRequired,
};

export default TopBannerBlock;
