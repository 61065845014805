/* eslint-disable view-models/no-unused-meta */
/* eslint-disable react/prop-types */
// TODO: remove view-models/no-unused-meta and eslint-disable react/prop-types
import React from 'react';
import PropTypes from 'prop-types';
import FilterItem from 'components/models/filter-item';
import cn from 'classnames';
import Icon from 'components/icon';

const TagsFilterItem = ({
  color,
  text,
  value,
  selected = false,
  onClick = () => {},
  className,
}) => {
  var buttonStyle = {
    backgroundColor: color,
  };
  return (
    <button
      defaultValue={value}
      className={cn('tags-filter-item', className, {
        'tags-filter-item_selected': selected,
      })}
      onClick={() => onClick()}
      style={buttonStyle}
    >
      {selected && <Icon name="check" className="tags-filter-item__icon" />}
      <span className="tags-filter-item__text">{text}</span>
    </button>
  );
};

TagsFilterItem.propTypes = Object.assign({}, FilterItem.propTypes, {
  color: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
});

TagsFilterItem.viewModelMeta = {
  className: 'ignore',
};

export default TagsFilterItem;
