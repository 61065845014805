import PropTypes from 'prop-types';
import Teaser from 'components/teaser/teaser';

const LoadMoreTeasersResults = {};

LoadMoreTeasersResults.propTypes = {
  items: PropTypes.arrayOf(Teaser.propTypes),
  hasMore: PropTypes.bool.isRequired,
};

export default LoadMoreTeasersResults;
