import PropTypes from 'prop-types';

const QAItem = {};

QAItem.propTypes = {
  heading: PropTypes.string.isRequired,
  contentHtml: PropTypes.string.isRequired,
};

export default QAItem;
