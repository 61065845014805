import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import IngredientName from './ingredient-name';
import IngredientUnit from './ingredient-unit';
import IngredientAmount from './ingredient-amount';
import PrintOrder from './print-order.js';
import idHelper from 'js/id-helper';

const Ingredient = ({ amount, printOrder, unit, name }) => {
  const uniqueIngredientId = useMemo(
    () => idHelper.generateUniqueId('ingredient-item'),
    []
  );

  return (
    <React.Fragment>
      <input
        id={uniqueIngredientId}
        type="checkbox"
        className="ingredients__section-item-checkbox"
      />
      <label
        className="ingredients__section-item-label"
        htmlFor={uniqueIngredientId}
      >
        <span className="ingredients__section-item-checkbox-icon" />
        {printOrder && (
          <span className="ingredients__section-item-content">
            {printOrder === PrintOrder.aui && (
              <React.Fragment>
                <IngredientAmount number={amount} />
                {unit && <IngredientUnit {...unit} amount={amount} />}
                {name && <IngredientName {...name} amount={amount} />}
              </React.Fragment>
            )}
            {printOrder === PrintOrder.iau && (
              <React.Fragment>
                {name && <IngredientName {...name} amount={amount} />}
                <IngredientAmount number={amount} />
                {unit && <IngredientUnit {...unit} amount={amount} />}
              </React.Fragment>
            )}
            {printOrder === PrintOrder.iua && (
              <React.Fragment>
                {name && <IngredientName {...name} amount={amount} />}
                {unit && <IngredientUnit {...unit} amount={amount} />}
                <IngredientAmount number={amount} />
              </React.Fragment>
            )}
          </span>
        )}
      </label>
    </React.Fragment>
  );
};

Ingredient.propTypes = {
  printOrder: PropTypes.oneOf(Object.values(PrintOrder)),
  amount: PropTypes.number,
  unit: PropTypes.shape({
    plural: PropTypes.string,
    singular: PropTypes.string,
  }),
  name: PropTypes.shape({
    plural: PropTypes.string,
    singular: PropTypes.string,
  }),
};

Ingredient.viewModelMeta = 'ignore';

export default Ingredient;
