import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import FloatingTags from 'components/floating-tags';
import FloatingTag from 'components/floating-tag';
import {
  Accordion as AccordionComponent,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion';
import AccordionBlockItem from './accordion-block-item';
import * as Components from '../../app.components';

const AccordionBlock = ({
  autoCollapse = true,
  heading,
  headingTags,
  items,
}) => {
  return (
    <div className="accordion-block">
      <div className="accordion-block__container">
        <FloatingTags
          items={headingTags}
          className="floating-tags_in-accordion"
        >
          <h2 className="accordion-block__heading">{heading}</h2>
        </FloatingTags>
        <AccordionComponent
          allowMultipleExpanded={!autoCollapse}
          allowZeroExpanded={true}
        >
          {items.length > 0 &&
            items.map((item, index) => {
              return (
                <AccordionItem key={index}>
                  <AccordionItemState>
                    {({ expanded }) => (
                      <Fragment>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            <div className="accordion-block__item-heading">
                              <span className="accordion-block__item-number">
                                {index + 1}
                              </span>
                              {item.heading}
                            </div>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          {expanded &&
                            item.content &&
                            item.content.map((block, index) => {
                              const Block = Components[block.componentName];
                              if (!Block) {
                                return null;
                              }
                              return (
                                <div
                                  key={block.componentName + index}
                                  className="accordion-block__item-content"
                                >
                                  <Block {...block} place="accordion" />
                                </div>
                              );
                            })}
                        </AccordionItemPanel>
                      </Fragment>
                    )}
                  </AccordionItemState>
                </AccordionItem>
              );
            })}
        </AccordionComponent>
      </div>
    </div>
  );
};

AccordionBlock.propTypes = {
  heading: PropTypes.string,
  headingTags: PropTypes.arrayOf(PropTypes.exact(FloatingTag.propTypes)),
  autoCollapse: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.exact(AccordionBlockItem.propTypes))
    .isRequired,
};

export default AccordionBlock;
