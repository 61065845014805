import MenuSection from 'components/models/menu-section';
import PropTypes from 'prop-types';

const FooterMenu = {};

FooterMenu.propTypes = {
  mainMenu: PropTypes.exact(MenuSection.propTypes),
  aboutUs: PropTypes.exact(MenuSection.propTypes),
  followUs: PropTypes.exact(MenuSection.propTypes),
};

export default FooterMenu;
