/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TeasersList from 'components/teasers-list/teasers-list';
import ViewSelect from 'components/view-select';
import useBreakpoints from 'hooks/use-breakpoints';
import Masonry from 'react-responsive-masonry';
import LoadMoreButton from 'components/load-more-button/load-more-button';
import Teaser from 'components/teaser';
import LoadingIndicator from 'components/loading-indicator/loading-indicator';
import apiHelper from 'js/api-helper';
import replaceQueryParameters from '@creuna/utils/replace-query-parameters';

const TeasersListAdvanced = ({
  hasMore,
  viewSelectLabel,
  items,
  loadMoreButtonText,
  loadMoreEndpoint,
}) => {
  const { isMobile } = useBreakpoints(false);
  const [listView, setListView] = useState('one-column');
  const [currentItems, setCurrentItems] = useState(items);
  const [currentHasMore, setCurrentHasMore] = useState(hasMore);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);

  const onViewChange = view => {
    setListView(view);
  };

  const onLoadMoreClick = () => {
    if (isLoading) {
      return;
    }

    const newPage = page + 1;
    setIsLoading(true);
    apiHelper
      .get(replaceQueryParameters(loadMoreEndpoint, { page: newPage }))
      .then(results => {
        setCurrentItems(currentItems => [...currentItems, ...results.items]);
        setCurrentHasMore(results.hasMore);
        setPage(newPage);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="teasers-list-advanced">
      {isMobile && (
        <ViewSelect
          hint={viewSelectLabel}
          selectedView={listView}
          onChange={view => onViewChange(view)}
          className="teasers-list-advanced__view-select"
        />
      )}

      {isMobile && listView === 'two-columns' ? (
        <div className="teasers-list-advanced__two-columns-view">
          <Masonry columnsCount={2} gutter="10px">
            {currentItems &&
              currentItems.map((recipe, index) => (
                <div
                  className="teasers-list-advanced__two-columns-view-item"
                  key={index}
                >
                  <Teaser {...recipe} className="teaser_in-two-columns-view" />
                </div>
              ))}
          </Masonry>
        </div>
      ) : (
        <React.Fragment>
          {currentItems && <TeasersList items={currentItems} />}
        </React.Fragment>
      )}
      {currentHasMore && (
        <LoadMoreButton
          value={loadMoreEndpoint}
          text={loadMoreButtonText}
          onClick={onLoadMoreClick}
          className="teasers-list-advanced__load-more-button"
        />
      )}
      {isLoading && <LoadingIndicator />}
    </div>
  );
};

TeasersListAdvanced.propTypes = Object.assign({}, TeasersList.propTypes, {
  viewSelectLabel: PropTypes.string,
  hasMore: PropTypes.bool,
  loadMoreEndpoint: PropTypes.string,
  loadMoreButtonText: PropTypes.string,
});

export default TeasersListAdvanced;
