import React from 'react';
import Teaser, { TeaserWidths } from 'components/teaser';
import PropTypes from 'prop-types';
import cn from 'classnames';

const getTeaserWidth = (rowIndex, teaserIndex, teasersLength) => {
  let teaserWidth = TeaserWidths.full;

  if (!teasersLength || teasersLength === 1) {
    return teaserWidth;
  }

  if (
    (teaserIndex % 2 === 0 && rowIndex % 2 === 0) ||
    (teaserIndex % 2 !== 0 && rowIndex % 2 !== 0)
  ) {
    teaserWidth = TeaserWidths.oneThird;
  } else {
    teaserWidth = TeaserWidths.twoThird;
  }
  return teaserWidth;
};

const TeasersList = ({ teaserHeadingTagLevel = 2, items }) => {
  const allItems = [...items];
  const teaserRows = [];
  while (allItems.length) {
    teaserRows.push(allItems.splice(0, 2));
  }

  return teaserRows && teaserRows.length > 0 ? (
    <div className="teasers-list">
      {teaserRows.map((row, rowIndex) => (
        <div className="teasers-list__row" key={rowIndex}>
          {row &&
            row.map((teaser, teaserIndex) => {
              const teaserWidth = getTeaserWidth(
                rowIndex,
                teaserIndex,
                row.length
              );
              return (
                <div
                  className={cn(
                    'teasers-list__item',
                    `teasers-list__item_${teaserWidth}`
                  )}
                  key={teaserIndex}
                >
                  <Teaser
                    {...teaser}
                    headingTagLevel={teaserHeadingTagLevel}
                    teaserWidth={teaserWidth}
                  />
                </div>
              );
            })}
        </div>
      ))}
    </div>
  ) : null;
};

TeasersList.propTypes = {
  teaserHeadingTagLevel: PropTypes.oneOf([2, 3]),
  items: PropTypes.arrayOf(PropTypes.exact(Teaser.propTypes)),
};

TeasersList.viewModelMeta = {
  teaserHeadingTagLevel: 'ignore',
};

export default TeasersList;
