import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Image from 'components/image';

const Logo = ({ className, url, image, imageClass }) => {
  return (
    <a className={cn('logo', className)} href={url}>
      {image && <Image {...image} className={cn('logo__image', imageClass)} />}
    </a>
  );
};

Logo.propTypes = {
  className: PropTypes.string,
  imageClass: PropTypes.string,
  url: PropTypes.string,
  image: PropTypes.exact(Image.propTypes),
};

Logo.viewModelMeta = {
  className: 'ignore',
  imageClass: 'ignore',
};

export default Logo;
