import React from 'react';
import PropTypes from 'prop-types';
import SearchTeaser from 'components/search-teaser/search-teaser';
import useBreakpoints from 'hooks/use-breakpoints';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import LoadingIndicator from 'components/loading-indicator';
import ErrorMessage from 'components/error-message';
import LoadMoreButton from 'components/load-more-button/load-more-button';

const SearchSection = ({
  heading,
  hits,
  hasMore,
  loadMoreButtonText,
  onLoadMoreClick = () => {},
  isLoading,
  showError,
  errorMessage,
}) => {
  const { isDesktop } = useBreakpoints(true);

  return (
    <div className="search-section">
      <div className="search-section__container">
        <h2 className="search-section__heading">{heading}</h2>
        {hits && (
          <React.Fragment>
            {isDesktop ? (
              <ul className="search-section__items">
                {hits.map((teaser, index) => (
                  <li key={index} className="search-section__item">
                    <SearchTeaser
                      {...teaser}
                      className="search-section__teaser"
                    />
                  </li>
                ))}
              </ul>
            ) : (
              <div className="search-section__items">
                <ResponsiveMasonry
                  columnsCountBreakPoints={{ 0: 1, 600: 2 }}
                  className="search-section__responsive-grid"
                >
                  <Masonry gutter="10px">
                    {hits.map((teaser, index) => (
                      <div key={index} className="search-section__item">
                        <SearchTeaser
                          {...teaser}
                          className="search-section__teaser"
                        />
                      </div>
                    ))}
                  </Masonry>
                </ResponsiveMasonry>
              </div>
            )}
          </React.Fragment>
        )}
        {hasMore && (
          <LoadMoreButton text={loadMoreButtonText} onClick={onLoadMoreClick} />
        )}
        {isLoading && <LoadingIndicator />}
        {showError && <ErrorMessage text={errorMessage} />}
      </div>
    </div>
  );
};

SearchSection.propTypes = {
  errorMessage: PropTypes.string,
  pageType: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  hits: PropTypes.arrayOf(PropTypes.exact(SearchTeaser.propTypes)),
  hasMore: PropTypes.bool.isRequired,
  onLoadMoreClick: PropTypes.func,
  loadMoreButtonText: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  showError: PropTypes.bool,
};

SearchSection.viewModelMeta = {
  loadMoreButtonText: 'ignore',
  isLoading: 'ignore',
  showError: 'ignore',
  errorMessage: 'ignore',
};

export default SearchSection;
