import ReactComponent from 'components/react-component/react-component';
import PropTypes from 'prop-types';

const AccordionBlockItem = {};

AccordionBlockItem.propTypes = {
  heading: PropTypes.string.isRequired,
  content: PropTypes.arrayOf(PropTypes.shape(ReactComponent.propTypes))
    .isRequired,
};

export default AccordionBlockItem;
