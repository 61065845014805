import PageHeading from 'components/page-heading';
import React, { useEffect, useState } from 'react';
import apiHelper from 'js/api-helper';
import PropTypes from 'prop-types';
import TagsFilter from 'components/tags-filter/tags-filter';
import RecipeTeaser from 'components/recipe-teaser/recipe-teaser';
import Pager from 'components/pager';
import useBreakpoints from 'hooks/use-breakpoints';
import useDidUpdateEffect from 'hooks/use-did-update-effect';
import Masonry from 'react-responsive-masonry';
import RecipeFilters from 'components/recipe-filters';
import ViewSelect from 'components/view-select';
import formHelper from 'js/form-helper';
import LoadingIndicator from 'components/loading-indicator/loading-indicator';
import ErrorMessage from 'components/error-message/error-message';
import PagerLang from 'components/models/pager-lang/pager-lang';

const pageNumberQueryParamName = 'page';
const skipFiltersQueryParamName = 'skipFilters';

const RecipesOverviewPage = ({ heading, lang, searchEndpoint }) => {
  const { isMobile } = useBreakpoints(true);
  const [results, setResults] = useState(null);
  const [query, setQuery] = useState(formHelper.createQuery());
  const [showError, setShowError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [initDone, setInitDone] = useState(false);
  const [listView, setListView] = useState('one-column');

  // eslint-disable-next-line no-unused-vars
  const updateQuery = (updateFunc = q => {}) => {
    setQuery(q => {
      q = Object.assign({}, q);
      updateFunc(q);
      return q;
    });
  };

  const initLoadedFilter = filter => {
    formHelper.updateFilterFromQuery(query, filter.name, filter.items);
  };

  const onFilterChange = e => {
    updateQuery(q => {
      q[pageNumberQueryParamName] = 1;

      return formHelper.updateQueryFromFilter(q, e.name, e.items);
    });
  };

  const search = () => {
    setShowError(false);
    setIsLoading(true);

    const requestQuery = { ...query };

    if (results?.filters && results?.pager && query.page !== 1) {
      requestQuery[skipFiltersQueryParamName] = true;
    }

    apiHelper
      .execute(searchEndpoint, requestQuery)
      .then(results => {
        if (results) {
          if (results.categoriesFilter) {
            initLoadedFilter(results.categoriesFilter);
          }

          if (results.filters) {
            results.filters.forEach(initLoadedFilter);
          }

          const { filters: responseFilters, ...restResponseResults } = results;

          setResults(prevResults => ({
            ...prevResults,
            ...restResponseResults,
            ...(responseFilters && {
              filters: responseFilters,
            }),
          }));
        }
      })
      .catch(() => {
        setShowError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const init = () => {
    updateQuery(q => {
      formHelper.fillQueryFromPageQueryString(q);

      if (q[pageNumberQueryParamName]) {
        q[pageNumberQueryParamName] = +q[pageNumberQueryParamName];
      }

      return q;
    });
  };

  useDidUpdateEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [results?.pager?.page]);

  useEffect(() => {
    if (!initDone) {
      init();
      setInitDone(true);
    }
  }, []);

  useEffect(() => {
    if (initDone) {
      formHelper.updatePageQueryString(query);
      search();
    }
  }, [query, initDone]);

  const onPageChange = page => {
    updateQuery(q => (q[pageNumberQueryParamName] = page));
  };

  return (
    <div className="recipes-overview-page">
      {heading && (
        <PageHeading
          text={heading}
          className="page-heading_small recipes-overview-page__heading"
        />
      )}

      {results && (
        <div>
          <div className="recipes-overview-page__control-panel">
            <div className="recipes-overview-page__control-panel-group">
              {results.categoriesFilter && (
                <TagsFilter
                  {...results.categoriesFilter}
                  onChange={onFilterChange}
                  className="tags-filter_in-recipes-overview"
                />
              )}
            </div>

            <div className="recipes-overview-page__control-panel-group">
              <RecipeFilters
                filterHint={lang.filterHint}
                filterLabel={lang.filterLabel}
                clearFiltersLabel={lang.clearFiltersLabel}
                totalResultsLabel={lang.totalResultsLabel}
                totalCount={results.totalCount}
                filters={results.filters}
                onFilterChange={onFilterChange}
              />

              {isMobile && (
                <ViewSelect
                  hint={lang.mobileViewHint}
                  selectedView={listView}
                  onChange={setListView}
                />
              )}
            </div>
          </div>

          {results.recipesList && results.recipesList.length > 0 ? (
            <React.Fragment>
              <div className="recipes-overview-page__teasers">
                {isMobile && listView === 'two-columns' ? (
                  <div className="recipes-overview-page__teasers-list recipes-overview-page__teasers-list_two-columns">
                    <Masonry columnsCount={2} gutter="10px">
                      {results.recipesList.map(recipe => (
                        <div
                          key={recipe.id}
                          className="recipes-overview-page__teaser-item"
                        >
                          <RecipeTeaser
                            {...recipe}
                            headingTagLevel={2}
                            className="recipes-overview-page__teaser"
                          />
                        </div>
                      ))}
                    </Masonry>
                  </div>
                ) : (
                  <ul className="recipes-overview-page__teasers-list">
                    {results.recipesList.map(recipe => (
                      <li
                        key={recipe.id}
                        className="recipes-overview-page__teaser-item"
                      >
                        <RecipeTeaser
                          {...recipe}
                          headingTagLevel={2}
                          className="recipes-overview-page__teaser"
                        />
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              {results.pager && (
                <Pager
                  {...results.pager}
                  page={query && query.page ? query.page : 1}
                  className="recipes-overview-page__pager"
                  lang={lang.pager}
                  onPageChange={onPageChange}
                />
              )}
            </React.Fragment>
          ) : (
            !isLoading && (
              <React.Fragment>
                <h3 className="recipes-overview-page__no-results-heading">
                  {lang.noResultsHeading}
                </h3>
                <div className="recipes-overview-page__no-results-text">
                  {lang.noResultsText}
                </div>
              </React.Fragment>
            )
          )}
        </div>
      )}
      {isLoading && <LoadingIndicator />}
      {showError && <ErrorMessage text={lang.errorMessage} />}
    </div>
  );
};

RecipesOverviewPage.propTypes = {
  heading: PropTypes.string,
  lang: PropTypes.shape({
    filterHint: PropTypes.string.isRequired,
    filterLabel: PropTypes.string.isRequired,
    mobileViewHint: PropTypes.string.isRequired,
    noResultsHeading: PropTypes.string.isRequired,
    noResultsText: PropTypes.string.isRequired,
    clearFiltersLabel: PropTypes.string.isRequired,
    totalResultsLabel: PropTypes.string.isRequired,
    errorMessage: PropTypes.string.isRequired,
    pager: PropTypes.shape(PagerLang.propTypes).isRequired,
  }),
  searchEndpoint: PropTypes.string.isRequired,
};

export default RecipesOverviewPage;
