import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ColoredTagsList from 'components/colored-tags-list';
import MenuSection from 'components/models/menu-section';
import useToggle from 'hooks/use-toggle';
import cn from 'classnames';
import Icon from 'components/icon';
import useClickOutside from 'hooks/use-click-outside';
import useEscape from 'hooks/use-escape';
import emitEvent, { events } from 'js/emit-event';

import Link from 'components/link';

const headerMenuPanelId = 'header-menu-panel';

const HeaderMenu = ({
  main,
  lang = {
    menuAriaLabel: 'Modalvindu for hovedmeny',
  },
  aboutUs,
  followUs,
  tagLinks,
  onMenuToggle,
}) => {
  const { isActive: isOpened, toggle, deactivate } = useToggle(false);
  const ref = React.useRef();
  const [mainSectionLinkFocuses, setMinSectionLinkFocuses] = useState(false);

  useEffect(() => {
    onMenuToggle(isOpened);
  }, [isOpened]);

  useEscape(deactivate);
  useClickOutside(ref, deactivate);

  const toggleText = isOpened
    ? lang && lang.closeLabel
      ? lang.closeLabel
      : ''
    : main && main.heading
      ? main.heading
      : '';
  const toggleIconName = isOpened ? 'menu-close' : 'menu';

  const linkTabIndex = isOpened ? null : -1;

  React.useEffect(() => {
    const bodyClass = 'header-menu-is-opened';
    isOpened
      ? document.body.classList.add(bodyClass)
      : document.body.classList.remove(bodyClass);
  }, [isOpened]);

  const onOpenSearch = () => {
    emitEvent(events.OPEN_QUICK_SEARCH, {});
    deactivate();
  };

  return (
    <div
      ref={ref}
      className={cn('header-menu', {
        'header-menu_opened': isOpened,
      })}
    >
      <button
        onClick={toggle}
        className="header-menu__toggle-button"
        aria-label={toggleText}
        aria-haspopup="true"
        aria-controls={headerMenuPanelId}
      >
        <span className="header-menu__toggle-button-text">{toggleText}</span>
        <Icon
          name={toggleIconName}
          className="header-menu__toggle-button-icon"
        />
      </button>
      <div
        className={cn('header-menu__panel', {
          'header-menu__panel_opened': isOpened,
        })}
        id={headerMenuPanelId}
        role="dialog"
        aria-hidden={!isOpened}
        aria-label={lang.menuAriaLabel}
      >
        <button
          className="header-menu__search-trigger"
          aria-label={lang.showQuickSearchLabel}
          onClick={onOpenSearch}
        >
          <Icon name="search" useFill style={{ color: '#000' }} />

          <span className="header-menu__search-trigger-text">
            {lang.searchButtonText}
          </span>
        </button>
        <div className="header-menu__panel-content">
          {main && main.links && (
            <ul
              className={cn('header-menu__main-section', {
                'header-menu__main-section_focused': mainSectionLinkFocuses,
              })}
            >
              {main.links.map((item, index) => {
                return (
                  <li key={index} className="header-menu__main-section-item">
                    <Link
                      {...item}
                      className={cn('header-menu__main-section-link', {
                        'header-menu__main-section-link_active': item.isActive,
                      })}
                      tabindex={linkTabIndex}
                      onFocus={() => {
                        setMinSectionLinkFocuses(true);
                      }}
                      onBlur={() => {
                        setMinSectionLinkFocuses(false);
                      }}
                    />

                    <Icon
                      name="arrow-right"
                      className="header-menu__main-section-icon"
                    />
                  </li>
                );
              })}
            </ul>
          )}
          {tagLinks && (
            <ColoredTagsList
              linksTabIndex={linkTabIndex}
              {...tagLinks}
              className="header-menu__colored-tags"
            />
          )}
          <div className="header-menu__bottom-group">
            {aboutUs && (
              <div className="header-menu__section">
                {aboutUs.heading && (
                  <h3 className="header-menu__section-heading">
                    {aboutUs.heading}
                  </h3>
                )}
                <ul className="header-menu__section-items">
                  {aboutUs.links.map((item, index) => {
                    return (
                      <li key={index} className="header-menu__section-item">
                        <Link
                          {...item}
                          className={cn('header-menu__section-link', {
                            'header-menu__section-link_active': item.isActive,
                          })}
                          tabindex={linkTabIndex}
                        />
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
            {followUs && (
              <div className="header-menu__section">
                {followUs.heading && (
                  <h3 className="header-menu__section-heading">
                    {followUs.heading}
                  </h3>
                )}
                <ul className="header-menu__section-items">
                  {followUs.links.map((item, index) => {
                    return (
                      <li key={index} className="header-menu__section-item">
                        <Link
                          {...item}
                          className={cn('header-menu__section-link', {
                            'header-menu__section-link_active': item.isActive,
                          })}
                          tabindex={linkTabIndex}
                        />
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

HeaderMenu.propTypes = {
  lang: PropTypes.shape({
    closeLabel: PropTypes.string.isRequired,
    showQuickSearchLabel: PropTypes.string.isRequired,
    searchButtonText: PropTypes.string.isRequired,
    menuAriaLabel: PropTypes.string.isRequired,
  }).isRequired,
  main: PropTypes.exact(MenuSection.propTypes),
  aboutUs: PropTypes.exact(MenuSection.propTypes),
  followUs: PropTypes.exact(MenuSection.propTypes),
  tagLinks: PropTypes.exact(ColoredTagsList.propTypes),
  onMenuToggle: PropTypes.func,
};

HeaderMenu.viewModelMeta = {
  onMenuToggle: 'ignore',
};

export default HeaderMenu;
