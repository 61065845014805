// TODO: refactor it to create EventBus instead

export const events = {
  OPEN_QUICK_SEARCH: 'openQuickSearch',
};

function emitEvent(name, eventData) {
  if (Object.values(events).indexOf(name) > -1) {
    let event = null;
    try {
      event = new CustomEvent(name, { detail: eventData });
    } catch (err) {
      event = document.createEvent('Event');
      event.initEvent(name, true, true);
      event.detail = eventData;
    }

    document.dispatchEvent(event);
  }
}

export default emitEvent;
