import React from 'react';
import PropTypes from 'prop-types';
import SharePanel from 'components/share-panel';
import RateRecipe from 'components/rate-recipe';
import cn from 'classnames';

const ShareAndRateRecipePanel = ({
  heading,
  shareRecipe,
  rateRecipe,
  className,
}) => {
  return (
    <div className={cn('share-and-rate-recipe-panel', className)}>
      {heading && (
        <h2 className="share-and-rate-recipe-panel__heading">{heading}</h2>
      )}
      <div className="share-and-rate-recipe-panel__content">
        {shareRecipe && (
          <SharePanel
            {...shareRecipe}
            className="share-and-rate-recipe-panel__share-recipe"
          />
        )}
        {rateRecipe && (
          <RateRecipe
            {...rateRecipe}
            className="share-and-rate-recipe-panel__rate-recipe"
          />
        )}
      </div>
    </div>
  );
};

ShareAndRateRecipePanel.propTypes = {
  heading: PropTypes.string,
  shareRecipe: PropTypes.exact(SharePanel.propTypes),
  rateRecipe: PropTypes.exact(RateRecipe.propTypes),
  className: PropTypes.string,
};

export default ShareAndRateRecipePanel;
