import PropTypes from 'prop-types';

const Tab = {};

Tab.propTypes = {
  name: PropTypes.string,
  contentId: PropTypes.string,
};

export default Tab;
