import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import apiHelper from 'js/api-helper';
import RateRecipeItem from './rate-recipe-item';
import recipeMessageBus from 'js/recipe-message-bus';
import cn from 'classnames';

const COOKIE_DATA_NAME = 'godfiskRecipesRating';

const RateRecipe = ({
  recipeId,
  currentRating,
  submitUrl,
  items,
  className,
  lang,
}) => {
  const [isRatedByUser, setIsRatedByUser] = useState(false);
  const [showError, setShowError] = useState(false);
  const [cookiesData, setCookiesData] = useState({});
  const [selectedItem, setSelectedItem] = useState({});
  const [isHintShown, setIsHintShown] = useState(false);
  const [rateConfirmation, setRateConfirmation] = useState(null);
  const [isTemporaryDisabled, setIsTemporaryDisabled] = useState(false);

  const setSelectedItemByValue = value => {
    const newSelectedItem = items.find(i => i.value === value);
    setSelectedItem(
      value && value !== 0 && newSelectedItem ? newSelectedItem : {}
    );
  };

  const isItemActive = (itemRating, currentRating) => {
    return itemRating <= currentRating;
  };

  const onItemsMouseLeave = () => {
    if (!isRatedByUser) {
      setSelectedItemByValue(currentRating);
      setIsHintShown(false);
    }
  };

  const onItemClick = () => {
    setIsRatedByUser(true);
    setIsHintShown(false);
    apiHelper
      .execute(submitUrl, {
        rateValue: selectedItem.value,
      })
      .then(response => {
        if (response.rating && response.ratingValue) {
          setRateConfirmation(response.confirmation);

          const newRecipeId = recipeId;
          const currentCookieData = cookiesData;
          const addCookieData = { [newRecipeId]: selectedItem.value };
          const newCookieData = { ...currentCookieData, ...addCookieData };

          Cookies.set(COOKIE_DATA_NAME, JSON.stringify(newCookieData), {
            expires: 365,
          });

          setCookiesData(newCookieData);
          setShowError(false);
          recipeMessageBus.publishNewRating(response.rating);
        } else {
          setShowError(true);
          setIsRatedByUser(false);
          setIsTemporaryDisabled(true);
        }
      })
      .catch(() => {
        setIsRatedByUser(false);
        setShowError(true);
        setIsTemporaryDisabled(true);
      });
  };

  useEffect(() => {
    const recipeCookiesString = Cookies.get(COOKIE_DATA_NAME);
    if (recipeCookiesString) {
      const recipeCookies = JSON.parse(recipeCookiesString);
      setCookiesData(recipeCookies);
      if (recipeCookies[recipeId]) {
        setRateConfirmation(lang.youHaveAlreadyRatedMessage);
      }
    }
    setSelectedItemByValue(currentRating);
  }, []);

  useEffect(() => {
    if (cookiesData && cookiesData[recipeId]) {
      setIsRatedByUser(true);
    } else {
      setIsRatedByUser(false);
    }
  }, [cookiesData]);

  useEffect(() => {
    if (isTemporaryDisabled) {
      setTimeout(() => {
        setIsTemporaryDisabled(false);
      }, 5000);
    }
  }, [isTemporaryDisabled]);

  return (
    <div className={cn('rate-recipe', className)}>
      <strong className="rate-recipe__heading">
        {lang.rateThisRecipeLabel}
      </strong>
      <div className="rate-recipe__content">
        {items && (
          <ul
            className="rate-recipe__items"
            onMouseLeave={() => onItemsMouseLeave()}
          >
            {items.map((item, index) => {
              return (
                <li key={index} className="rate-recipe__item-container">
                  <RateRecipeItem
                    {...item}
                    isActive={isItemActive(item.value, selectedItem.value)}
                    onMouseEnter={() => {
                      setSelectedItemByValue(item.value);
                      setIsHintShown(true);
                      setShowError(false);
                    }}
                    onFocus={() => {
                      setSelectedItemByValue(item.value);
                      setIsHintShown(true);
                      setShowError(false);
                    }}
                    onBlur={() => setIsHintShown(false)}
                    onMouseLeave={() => setIsHintShown(false)}
                    onClick={onItemClick}
                    isDisabled={isRatedByUser || isTemporaryDisabled}
                  />
                </li>
              );
            })}
          </ul>
        )}

        <div className="rate-recipe__notifications" role="status">
          {selectedItem && selectedItem.hint && isHintShown && !showError && (
            <div className="rate-recipe__hint">{selectedItem.hint}</div>
          )}
          {rateConfirmation && !showError && (
            <div className="rate-recipe__confirmation">{rateConfirmation}</div>
          )}
          {showError && (
            <div className="rate-recipe__error-text">{lang.errorText}</div>
          )}
        </div>
      </div>
    </div>
  );
};

RateRecipe.viewModelMeta = {
  className: 'ignore',
};

RateRecipe.propTypes = {
  className: PropTypes.string,
  recipeId: PropTypes.string.isRequired,
  currentRating: PropTypes.number.isRequired,
  submitUrl: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.exact(RateRecipeItem.propTypes)),
  lang: PropTypes.shape({
    rateThisRecipeLabel: PropTypes.string.isRequired,
    errorText: PropTypes.string.isRequired,
    youHaveAlreadyRatedMessage: PropTypes.string.isRequired,
  }).isRequired,
};

export default RateRecipe;
