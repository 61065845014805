import React from 'react';
import PropTypes from 'prop-types';
import PoorText from 'components/poor-text';
import Image from 'components/image';
import LinkButton from 'components/link-button';
import FloatingTags from 'components/floating-tags';
import FloatingTag from 'components/floating-tag';
import RecipeTeaser from 'components/recipe-teaser';
import useBreakpoints from 'hooks/use-breakpoints';
import cn from 'classnames';
import Carousel from 'components/carousel';

const WeeklyMenuBlock = ({
  heading,
  headingTags,
  introHtml,
  recipesList,
  sidePanel,
  seeAllButton,
}) => {
  const { isDesktopLargerThanIpad } = useBreakpoints(true);
  return (
    <div className="weekly-menu-block">
      <div className="weekly-menu-block__container">
        <div className="weekly-menu-block__header">
          {heading && (
            <FloatingTags
              items={headingTags}
              className="weekly-menu-block__heading-tags"
            >
              <h2 className="weekly-menu-block__heading">{heading}</h2>
            </FloatingTags>
          )}
          {introHtml && (
            <div className="weekly-menu-block__intro">
              <PoorText
                text={introHtml}
                className="weekly-menu-block__intro-text poor-text_in-weekly-menu"
              />
            </div>
          )}
          {isDesktopLargerThanIpad && seeAllButton && (
            <LinkButton
              {...seeAllButton}
              className="weekly-menu-block__see-all-button link-button_light-hover"
            />
          )}
        </div>

        <div className="weekly-menu-block__items">
          {recipesList && (
            <React.Fragment>
              {isDesktopLargerThanIpad ? (
                <React.Fragment>
                  {recipesList.map((teaser, index) => {
                    return (
                      <RecipeTeaser
                        {...teaser}
                        key={index}
                        className="weekly-menu-block__item"
                      />
                    );
                  })}
                </React.Fragment>
              ) : (
                <Carousel
                  items={recipesList}
                  itemComponentName="RecipeTeaser"
                />
              )}
            </React.Fragment>
          )}

          {sidePanel && (
            <div
              className={cn('weekly-menu-block__side-panel', {
                'weekly-menu-block__item': isDesktopLargerThanIpad,
              })}
            >
              {sidePanel.logo && (
                <Image
                  {...sidePanel.logo}
                  className="weekly-menu-block__side-panel-logo image_size_weekly-menu-side-panel-logo"
                />
              )}
              {sidePanel.heading && (
                <h3 className="weekly-menu-block__side-panel-heading">
                  {sidePanel.heading}
                </h3>
              )}
              {sidePanel.textHtml && (
                <PoorText
                  text={sidePanel.textHtml}
                  className="weekly-menu-block__side-panel-text poor-text_in-weekly-side-panel"
                />
              )}
            </div>
          )}
        </div>

        {!isDesktopLargerThanIpad && seeAllButton && (
          <LinkButton
            {...seeAllButton}
            className="weekly-menu-block__see-all-button"
          />
        )}
      </div>
    </div>
  );
};

WeeklyMenuBlock.propTypes = {
  heading: PropTypes.string,
  headingTags: PropTypes.arrayOf(PropTypes.exact(FloatingTag.propTypes)),
  introHtml: PropTypes.string,
  recipesList: PropTypes.arrayOf(PropTypes.exact(RecipeTeaser.propTypes)),
  sidePanel: PropTypes.shape({
    heading: PropTypes.string,
    textHtml: PropTypes.string,
    logo: PropTypes.exact(Image.propTypes),
  }),
  seeAllButton: PropTypes.exact(LinkButton.propTypes),
};

export default WeeklyMenuBlock;
