import React from 'react';
import PropTypes from 'prop-types';
import Image from 'components/image';
import Link from 'components/link';
import cn from 'classnames';

const topImageSizeBaseClass = 'image_size_top-image';

const TopImageBlock = ({
  image1,
  image2,
  place,
  imageCaption,
  highResPhotoDownloadLink,
}) => {
  const isDoubleImage = image1 && image2 ? true : false;
  const imageSizeClass = isDoubleImage
    ? place
      ? `${topImageSizeBaseClass}-half-${place}`
      : `${topImageSizeBaseClass}-half`
    : place
      ? `${topImageSizeBaseClass}-${place}`
      : topImageSizeBaseClass;

  return (
    <div
      className={cn('top-image-block', {
        'top-image-block_two-images': isDoubleImage,
      })}
    >
      <div
        className={cn('top-image-block__container', {
          [`top-image-block__container_in-${place}`]: place,
        })}
      >
        <div className="top-image-block__images">
          {image1 && (
            <div className="top-image-block__image">
              <Image {...image1} className={imageSizeClass} />
            </div>
          )}
          {image2 && (
            <div className="top-image-block__image">
              <Image {...image2} className={imageSizeClass} />
            </div>
          )}
          {highResPhotoDownloadLink && (
            <div className="top-image-block__high-res-photo-link-holder">
              <Link
                {...highResPhotoDownloadLink}
                className="top-image-block__high-res-photo-link"
              />
            </div>
          )}
        </div>
        {imageCaption && (
          <div className="top-image-block__image-caption">{imageCaption}</div>
        )}
      </div>
    </div>
  );
};

TopImageBlock.propTypes = {
  place: PropTypes.string,
  image1: PropTypes.exact(Image.propTypes),
  image2: PropTypes.exact(Image.propTypes),
  imageCaption: PropTypes.string,
  highResPhotoDownloadLink: PropTypes.exact(Link.propTypes),
};

export default TopImageBlock;
