import PropTypes from 'prop-types';
import Image from 'components/image';
import Video from 'components/video';

const TechniqueStep = {};

TechniqueStep.propTypes = {
  heading: PropTypes.string,
  introHtml: PropTypes.string,
  image: PropTypes.exact(Image.propTypes),
  video: PropTypes.exact(Video.propTypes),
};

export default TechniqueStep;
