import React from 'react';
import PropTypes from 'prop-types';
import LinkButton from 'components/link-button/link-button';
import Teaser, { TeaserWidths } from 'components/teaser';
import TeasersList from 'components/teasers-list/teasers-list';
import useBreakpoints from 'hooks/use-breakpoints';
import Carousel from 'components/carousel';
import RelatedItemsHeader from 'components/related-items-header/related-items-header';

const RelatedArticlesBlock = ({ seeAllLink, heading, items }) => {
  const { isDesktop } = useBreakpoints(true);
  return (
    <div className="related-articles-block">
      <RelatedItemsHeader heading={heading} seeAllLink={seeAllLink} />
      {isDesktop ? (
        <TeasersList items={items} teaserHeadingTagLevel={3} />
      ) : (
        <div className="related-articles-block__container">
          <Carousel
            items={items.map(item => ({
              ...item,
              teaserWidth: TeaserWidths.oneThird,
            }))}
            itemComponentName="Teaser"
          />
          {seeAllLink && (
            <LinkButton
              {...seeAllLink}
              className="related-articles-block__see-all-button"
            />
          )}
        </div>
      )}
    </div>
  );
};

RelatedArticlesBlock.propTypes = {
  seeAllLink: PropTypes.exact(LinkButton.propTypes),
  heading: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.exact(Teaser.propTypes)).isRequired,
};

export default RelatedArticlesBlock;
