import React from 'react';
import PropTypes from 'prop-types';
import LinkButton from 'components/link-button';
import ColoredTag from 'components/colored-tag';
import SearchForm from 'components/search-form';
import PoorText from 'components/poor-text';

const NotFoundPage = ({
  heading,
  tag1Text,
  tag2Text,
  introHtml,
  searchForm,
  homePageButton,
}) => {
  return (
    <div className="not-found-page">
      <div className="not-found-page__container">
        <div className="not-found-page__heading-container">
          {heading && <h1 className="not-found-page__heading">{heading}</h1>}
          <ColoredTag
            text={tag1Text}
            color="#FFEA79"
            className="not-found-page__colored-tag not-found-page__colored-tag_left"
          />
          <ColoredTag
            text={tag2Text}
            color="#C5F6FF"
            className="not-found-page__colored-tag not-found-page__colored-tag_right"
          />
        </div>
        {introHtml && (
          <PoorText
            text={introHtml}
            className="not-found-page__text poor-text_in-not-found-page"
          />
        )}
        <SearchForm {...searchForm} />
        {homePageButton && (
          <LinkButton {...homePageButton} className="not-found-page__btn" />
        )}
      </div>
    </div>
  );
};

NotFoundPage.propTypes = {
  heading: PropTypes.string,
  tag1Text: PropTypes.string,
  tag2Text: PropTypes.string,
  introHtml: PropTypes.string,
  searchForm: PropTypes.exact(SearchForm.propTypes),
  homePageButton: PropTypes.exact(LinkButton.propTypes),
};

export default NotFoundPage;
