import React from 'react';
import PropTypes from 'prop-types';
import RecipeTeaser from 'components/recipe-teaser/recipe-teaser';
import LinkButton from 'components/link-button/link-button';
import useBreakpoints from 'hooks/use-breakpoints';
import Carousel from 'components/carousel';
import RelatedItemsHeader from 'components/related-items-header/related-items-header';

const RelatedRecipesBlock = ({ seeAllLink, heading, items }) => {
  const { isDesktop } = useBreakpoints(true);
  return (
    <div className="related-recipes-block">
      <RelatedItemsHeader heading={heading} seeAllLink={seeAllLink} />
      {isDesktop ? (
        <React.Fragment>
          <div className="related-recipes-block__teasers">
            <ul className="related-recipes-block__teasers-list">
              {items.map((teaser, index) => (
                <li key={index} className="related-recipes-block__teasers-item">
                  <RecipeTeaser
                    {...teaser}
                    className="related-recipes-block__teaser"
                  />
                </li>
              ))}
            </ul>
          </div>
        </React.Fragment>
      ) : (
        <div className="related-recipes-block__container">
          <Carousel items={items} itemComponentName="RecipeTeaser" />
          {seeAllLink && (
            <LinkButton
              {...seeAllLink}
              className="related-recipes-block__see-all-button"
            />
          )}
        </div>
      )}
    </div>
  );
};

RelatedRecipesBlock.propTypes = {
  seeAllLink: PropTypes.exact(LinkButton.propTypes),
  heading: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.exact(RecipeTeaser.propTypes)).isRequired,
};

export default RelatedRecipesBlock;
