import React from 'react';
import PropTypes from 'prop-types';
import Image from 'components/image';
import RichText from 'components/rich-text';
import cn from 'classnames';

// eslint-disable-next-line view-models/no-errors
export const position = {
  left: 'left',
  right: 'right',
};

const TextWithImageBlock = ({ heading, textHtml, image, imagePosition }) => {
  return (
    <div
      className={cn('text-with-image-block', {
        [`text-with-image-block_image-position_${imagePosition}`]:
          imagePosition,
      })}
    >
      <div className="text-with-image-block__image-container">
        {image && (
          <Image
            {...image}
            className="text-with-image-block__image image_size_text-with-image-block"
          />
        )}
      </div>
      <div className="text-with-image-block__text-container">
        <h2 className="text-with-image-block__heading">{heading}</h2>
        <RichText text={textHtml} />
      </div>
    </div>
  );
};

TextWithImageBlock.propTypes = {
  heading: PropTypes.string,
  textHtml: PropTypes.string,
  image: PropTypes.exact(Image.propTypes),
  imagePosition: PropTypes.oneOf(Object.values(position)).isRequired,
};

// eslint-disable-next-line view-models/no-errors
export default TextWithImageBlock;
