import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const FormInputText = ({ label, name, placeholder, value, className }) => {
  return (
    <input
      className={cn('form-input-text', className)}
      type="text"
      name={name}
      placeholder={placeholder}
      aria-label={label}
      defaultValue={value}
    />
  );
};

FormInputText.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  className: PropTypes.string,
};

FormInputText.viewModelMeta = 'ignore';

export default FormInputText;
