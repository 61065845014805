const req = require.context('./', true, /\.svg$/);

const icons = req.keys().reduce((icons, filename) => {
  const id = filename.replace('./', '').replace('.svg', '');
  const component = req(filename);

  // After updating the React version we get this warning (Support for defaultProps will be removed from function components in a future major release.
  // Use JavaScript default parameters instead), because the module https://www.npmjs.com/package/svg-react-loader which was updated long time ago
  // generates React components where default svg params used as defaultProps. The solution is to avoid using defaultProps by passing a custom object instead
  if (component.defaultProps) {
    component.customDefaultProps = component.defaultProps;
    delete component.defaultProps;
  }

  return { ...icons, [id]: component };
}, {});

export default icons;
