import PropTypes from 'prop-types';
import MenuLink from 'components/models/menu-link';

const MenuSection = {};

MenuSection.propTypes = {
  heading: PropTypes.string,
  links: PropTypes.arrayOf(PropTypes.exact(MenuLink.propTypes)),
};

export default MenuSection;
