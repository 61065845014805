import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Logo from 'components/logo';
import HeaderMenu from 'components/header-menu';
import QuickSearch from 'components/quick-search';
import FocusTrap from 'focus-trap-react';

const Header = ({ logo, headerMenu, quickSearch }) => {
  const [isHeaderMenuActive, setIsHeaderMenuActive] = useState(false);

  return (
    <div className="header">
      <FocusTrap active={isHeaderMenuActive}>
        <div className="header__container">
          {quickSearch && (
            <QuickSearch {...quickSearch} className="header__quick-search" />
          )}
          <Logo
            {...logo}
            className="header__logo"
            imageClass="image_size_header-logo"
          />
          {headerMenu && (
            <HeaderMenu {...headerMenu} onMenuToggle={setIsHeaderMenuActive} />
          )}
        </div>
      </FocusTrap>
    </div>
  );
};

Header.propTypes = {
  quickSearch: PropTypes.exact(QuickSearch.propTypes),
  logo: PropTypes.exact(Logo.propTypes),
  headerMenu: PropTypes.exact(HeaderMenu.propTypes),
};

export default Header;
