import React from 'react';
import PropTypes from 'prop-types';
import RichText from 'components/rich-text';
import {
  Accordion as AccordionComponent,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import QAItem from './q-a-item';

const QABlock = ({ autoCollapse = true, items }) => {
  return (
    <div className="qa-block">
      <div className="qa-block__container">
        <AccordionComponent
          allowMultipleExpanded={!autoCollapse}
          allowZeroExpanded={true}
        >
          {items.length > 0 &&
            items.map((item, index) => {
              return (
                <AccordionItem key={index}>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <div className="qa-block__item-heading">
                        {item.heading}
                      </div>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <RichText
                      text={item.contentHtml}
                      className="qa-block__item-content"
                    />
                  </AccordionItemPanel>
                </AccordionItem>
              );
            })}
        </AccordionComponent>
      </div>
    </div>
  );
};

QABlock.propTypes = {
  autoCollapse: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.exact(QAItem.propTypes)).isRequired,
};

export default QABlock;
