import React from 'react';
import PropTypes from 'prop-types';
import ViewSelectItem from './view-select-item';
import cn from 'classnames';

const ViewSelect = ({
  hint,
  items = [
    {
      view: 'one-column',
      iconName: 'list-view-one-column',
    },
    {
      view: 'two-columns',
      iconName: 'list-view-two-columns',
    },
  ],
  selectedView,
  onChange = () => {},
  className,
}) => {
  return (
    <div className={cn('view-select', className)}>
      {hint && <strong className="view-select__label">{hint}</strong>}
      {items &&
        items.map((item, index) => (
          <ViewSelectItem
            {...item}
            key={index}
            onChange={view => onChange(view)}
            selected={item.view === selectedView}
          />
        ))}
    </div>
  );
};

ViewSelect.propTypes = {
  hint: PropTypes.string,
  selectedView: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.exact(ViewSelectItem.propTypes)),
  onChange: PropTypes.func,
  className: PropTypes.string,
};

ViewSelect.viewModelMeta = 'ignore';

export default ViewSelect;
