import isRunningOnClient from '@creuna/utils/is-running-on-client';

function minWidth(size) {
  return function () {
    return isRunningOnClient
      ? window.matchMedia(`(min-width: ${size}px)`).matches
      : null;
  };
}

const breakpoints = {
  s: 0,
  m: 768,
  l: 1024,
  ipadPro: 1025,
};

export default {
  s: minWidth(breakpoints.s),
  m: minWidth(breakpoints.m),
  l: minWidth(breakpoints.l),
  ipadPro: minWidth(breakpoints.ipadPro),
};
