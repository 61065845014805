import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-responsive-modal';

const MODAL_ROOT_CLASS = 'modal';

const ModalComponent = ({
  children,
  isOpened = false,
  titleAriaLabel,
  id,
  onClose = () => {},
}) => {
  useEffect(() => {
    if (isOpened) {
      document.body.classList.add(`${MODAL_ROOT_CLASS}__body-modal-opened`);
    } else {
      document.body.classList.remove(`${MODAL_ROOT_CLASS}__body-modal-opened`);
    }

    return () => {
      document.body.classList.remove(`${MODAL_ROOT_CLASS}__body-modal-opened`);
    };
  }, [isOpened]);

  return (
    <Modal
      open={isOpened}
      classNames={{
        root: MODAL_ROOT_CLASS,
        modal: `${MODAL_ROOT_CLASS}__wrapper`,
        closeButton: `${MODAL_ROOT_CLASS}__close-btn`,
      }}
      onClose={onClose}
      ariaLabelledby={`${id}-modal-title`}
      modalId={id}
      closeOnEsc
      closeOnOverlayClick={false}
    >
      {titleAriaLabel && (
        <p id={`${id}-modal-title`} className={`${MODAL_ROOT_CLASS}__title`}>
          {titleAriaLabel}
        </p>
      )}
      {children}
    </Modal>
  );
};

ModalComponent.propTypes = {
  isOpened: PropTypes.bool,
  children: PropTypes.node,
  onClose: PropTypes.func,
  titleAriaLabel: PropTypes.string,
  id: PropTypes.string,
};

ModalComponent.viewModelMeta = 'ignore';

export default ModalComponent;
