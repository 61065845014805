import React from 'react';
import PropTypes from 'prop-types';
import ColoredTag from 'components/colored-tag';
import cn from 'classnames';

const ColoredTagsList = ({ items, className, linksTabIndex }) => {
  const hasActiveItem = items.filter(x => x.isActive).length > 0;

  return (
    <ul
      className={cn('colored-tags-list', className, {
        'colored-tags-list_has-active': hasActiveItem,
      })}
    >
      {items &&
        items.map((item, index) => {
          return (
            <li className="colored-tags-list__item" key={index}>
              <ColoredTag
                {...item}
                linkTabIndex={linksTabIndex}
                className={cn('colored-tags-list__item-link', {
                  'colored-tags-list__item-link_active': item.isActive,
                })}
              />
            </li>
          );
        })}
    </ul>
  );
};

ColoredTagsList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.exact(ColoredTag.propTypes)),
  className: PropTypes.string,
  linksTabIndex: PropTypes.number,
};

ColoredTagsList.viewModelMeta = {
  className: 'ignore',
  linksTabIndex: 'ignore',
};

export default ColoredTagsList;
